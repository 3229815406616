// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

import {IProductEnvironment} from "./FacilitiesEvaluationEnvironment";
import {EProductType} from "../javascript.lib.mojo-base/model/ProductType";
import {FacilityAccessibilityProduct4} from "../product.facility/model/FacilityAccessibilityProduct4";

export const environment: IProductEnvironment = {

  filename: 'environment.facilities-856683.ts',
  isDevelopment: false,
  isProduction: true,
  isTest: false,

  firebaseConfig: {

    apiKey: "AIzaSyAJo0V4btz-ksv9JO6zMz7m_TDLSfivOIo",
    authDomain: "facilities-856683.firebaseapp.com",
    databaseURL: "https://facilities-856683-default-rtdb.europe-west1.firebasedatabase.app",
    messagingSenderId: "62809245452",
    projectId: "facilities-856683",
    storageBucket: "facilities-856683.appspot.com",
    appId: "1:62809245452:web:6895d95b85c6c7fb8feb29",
  },

  // curl -iLS https://us-central1-$_TARGET_ENV.cloudfunctions.net/public/functions/info
  cloudFunctionsUrl: 'https://us-central1-facilities-856683.cloudfunctions.net',

  productConfig: {

    firebaseAnswersRoot: '/answers-x/facilities',
    // product:  ProductHelper.cloneProduct( new FacilityAccessibilityProduct(), true )
    product:  FacilityAccessibilityProduct4.INSTANCE,
    productType: EProductType.facilities,

  },




};
