


export class BrowserAppEvaluationToolInfo {

  public version = '2023.8.28.1';

  constructor() {
  }

}
